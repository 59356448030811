import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public apiService: ApiService,) { }

  public setCard(each: any):void {
    this.apiService.validateCard(each.attributes.bin, each.attributes.bin_type).subscribe((res: any) => {
      if (res?.data?.attributes?.bin == each.attributes.bin
      ) {
        const current_user = JSON.parse(localStorage.getItem('currentUser') || '{}')
        if (current_user && current_user?.token) {
          current_user.token = res.data.attributes.session_token;
          localStorage.setItem('currentUser', JSON.stringify(current_user));
          localStorage.setItem('uc', res.data.attributes.id);
          window.location.reload();
        }
      }
    });
  }
}
