import { Component, OnInit, Input, Inject,HostListener,OnDestroy, ViewChildren } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import { ApiService } from '../../pages/common/api.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../pages/common/global-variables';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IUser, ICountry, ICard, IUIConfig, EnvironmentType } from './signup.interface';
import { environment } from '../../environments/environment';
import { Environment } from '../../environments/environment.interface';
import { ERROR_MESSAGES, ERROR_CODES, PROJECT_NAME} from '../../constants';
import { DeviceService } from '../../pages/common/device.service';

@Component({
  selector: 'app-signup',
  templateUrl: 'signup.component.html',
  styleUrls: ['signup.component.scss'],
})
export class SignupComponent implements OnInit,OnDestroy {
  public env:Environment = environment;
  @Input() reverifyFlag = false;

  @ViewChildren('cardFormRow') cardFormRows: any;

  public showAuthSection: boolean;
  public changeNumberFLag = false;
  public isLoggedIn = false;
  public environment_type: EnvironmentType;

  public mobileNumberForm: FormGroup;
  public otpForm: FormGroup;
  public cardDetailsForm: FormGroup;
  public otpFormInput = [
    'input1',
    'input2',
    'input3',
    'input4',
    'input5',
    'input6',
  ];
  public cardFormInput = ['inpOne', 'inpTwo', 'inpThree', 'inpFour'];
  public cardExpiryFormInput = ['cardMM','cardYY'];
  public submitted = false;
  private destroyed$: Subject<boolean> = new Subject();
  public submitBtnDisable = false;
  public cardInputEntered = false;
  public customErrorMessage: string | null = null;
  public submitBtnTitle: string | null = '';
  public focusedElement: string | null = null;
  public activeScreen?: string =
  new URL(window.location.href).searchParams.get('step') === 'verification'
    ? 'verification'
    : new URL(window.location.href).searchParams.get('step') ?? 'mobile';
  public previousScreen?: string = 'mobile';
  public screens = [
    'landing',//get start
    'mobile',//unlock my vouchers
    'otp',//verify otp
    'card',// authenticate my card
    'bankVerification',// waiting for verifcation
    'bankAuthenticated',// authenticated by bank
    'bankAuthenticationFailed',// authentication fail by bank
  ];
  public otpTimer: string | null = null;
  @ViewChildren('otpFormRow') otpFormRows: any;
  public addNewCard = false;
  public isCardsDropdownOpen = false;
  // public currentUser: IUser | null = null;
  public currentUser: any | null = null;
  public userCards: ICard[] = [];
  public activeUserCard?: ICard;
  @Input() public uiConfig?: IUIConfig;
  public showLoader = false;
  public isSignUp = true;
  public userCountry?: ICountry['attributes'];
  public isOtpResent = false;
  public mobileNumberLength: {
    min: number;
    max: number;
  } = { min: 10, max: 10 };

  public userId = '';
  public cardNumber = '';
  public mobileNumber = '';
  @HostListener('unloaded')
  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.setDeviceInfo();
  }
  constructor(
    private router: Router,
    public apiService: ApiService,
    public GlobalConstants: GlobalConstants,
    @Inject(DOCUMENT) private document: any,
    private fb: UntypedFormBuilder, 
    public deviceService: DeviceService,
  ) {
    this.showAuthSection = false;
    this.setDeviceInfo();
    this.environment_type = this.document.location.hostname.includes("sandbox") ? 'sandbox' : 'prod';
    //
    this.mobileNumberForm = this.fb.group({
      inpOne: [null, [Validators.required, this.checkMinLength(4)]],
      inpTwo: [null, [Validators.required, this.checkMinLength(4)]],
      inpThree: [null, [Validators.required, this.checkMinLength(4)]],
      inpFour: [null, [Validators.required, this.checkMinLength(4)]],
      mobileNumber: [
        null,
        [Validators.required, this.checkMinLength(10), this.checkMaxLength(10)],
      ],
      concent1: [
        null,
        [Validators.required],
      ],
      concent2: [
        null,
        [Validators.required],
      ],
    });
    this.otpForm = this.toFormGroup(this.otpFormInput);
    this.cardDetailsForm = this.fb.group({
      expiryMM: [null, [Validators.required, this.checkValidMMExpiry()]],
      expiryYY: [null, [Validators.required, this.checkValidYYExpiry()]],
    });
  }

  public ngOnInit(): void {
    this.mobileNumberForm?.valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe(() => {
      this.submitBtnDisable = !this.mobileNumberForm?.valid;
      this.submitted = false;
    });

    this.cardDetailsForm?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value: any) => {
        if ((this.cardNumber.trim() == '' || this.cardNumber.length == 0) &&
          (value?.expiryMM?.trim() == '' || value?.expiryMM == null) &&
          (value?.expiryYY?.trim() == '' || value?.expiryYY == null) 
        ) {
          this.cardInputEntered = false;
        } else {
          this.cardInputEntered = true;
        }
        this.submitted = false;
      });

    this.otpForm?.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.otpForm?.valid) {
          this.submitBtnDisable = false;
        }
      });
    const currentURL = new URL(window.location.href);
    const cardIdInRoute: string | null = currentURL.searchParams.get('cardId');
    if ((JSON.parse(localStorage.getItem('currentUser') as any) as IUser)?.token && cardIdInRoute) {
      this.handleUserLogin(currentURL, cardIdInRoute);
    }
    if (this.reverifyFlag) {
      this.setActiveScreen(this.screens[1]);
    } else if (!this.deviceService.isMobile) {
      this.setActiveScreen(this.screens[0]);
    }
  }

  private setDeviceInfo(): void {
    if (!this.deviceService.isMobile) {
      this.showAuthSection = true;
    }
    this.ngOnInit();
  }
  public toggleAuthSection(action:string): void {
    this.showAuthSection = true;
    this.activeScreen = 'landing';
    if(action === 'login'){
      this.login();
    } else{
      this.signup();
    }
  }
  public closeAuthSection(): void {
    this.showAuthSection = false;
  }

  private handleUserLogin(currentURL: URL, cardIdInRoute?: string): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') as any);
    this.mobileNumberForm?.patchValue({
      mobileNumber: this.currentUser?.mobile,
    });

    const cardTokenMap: any =
      this.apiService.getFromLocalStorage('cardTokenMap') ??
      {};
    const is3DSVerified =
      this.apiService.getFromLocalStorage(
        'is3dsVerified'
      ) === true;
    const paramStep = currentURL.searchParams.get('step');
    if (cardIdInRoute) {
      this.apiService.saveInLocalStorage(
        'userCardId',
        cardIdInRoute
      );
    }
    if (cardIdInRoute && cardIdInRoute in cardTokenMap) {
      if (paramStep === 'verification') {
        const transactionId = cardTokenMap[cardIdInRoute];
        this.apiService.verifyTransaction(transactionId).subscribe({
          // TODO: add interface instead of `any`
          next: (res: any) => {
            /**
             * This means the user might have cancelled the flow or enterred incorrect OTP details
             * This can also happen in case the flow is timed out or interrupted
             * In this case, we will redirect user to the card screen
             */
            if (res?.status !== 'success') {
              //TODO: add error message on 3ds auth fail.
              this.setActiveScreen('card', cardIdInRoute);
            }

            if (res?.authenticated && res?.card_id && res?.token) {
              const cardTokenMap = this.getCardTokenMap();
              cardTokenMap[res.card_id] = res.token;
              this.apiService.saveInLocalStorage(
                'cardTokenMap',
                cardTokenMap
              );
              this.apiService.saveInLocalStorage(
                'is3dsVerified',
                'true'
              );
              this.setActiveScreen('bankAuthenticated', res.card_id);
            } else {
              this.setActiveScreen('bankAuthenticationFailed');
            }
          },
          error: (err) => {
            console.error('error occured ', err);
          },
        });
      } else {
        this.setActiveScreen('bankAuthenticationFailed');
        /**
         * the below is already happenign in setActiveScreen('card')
         *
         * this.fetchUserDetails();
         * this.fetchUserCards(cardIdInRoute ?? undefined);
         */
      }
    } else {
      this.setActiveScreen('bankAuthenticationFailed');
    }
  }

  private getCardTokenMap(): Record<string, string> {
    return (
      this.apiService.getFromLocalStorage<
        Record<string, string>
      >('cardTokenMap') ?? {}
    );
  }

  public redirectToOffer(): void {
    this.router.navigate(["/offer"]);
  }

  public ngDoCheck(): void {
    this.isLoggedIn = this.apiService.isAuthenticated();
  }

  private checkMinLength(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control?.value;
      return value?.toString()?.length < minLength ? { minLength: true } : null;
    };
  }

  private checkMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control?.value;
      return value?.toString()?.length > maxLength ? { maxLength: true } : null;
    };
  }

  private toFormGroup(elements: string[]) {
    const group: any = {};
    elements.forEach((key) => {
      group[key] = new UntypedFormControl('', Validators.required);
    });
    return new UntypedFormGroup(group);
  }

  private checkValidMMExpiry(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let validMMDate = control?.value?.toString()?.length ? false : true;
      const monthInput = control?.value?.toString() ? parseInt(control?.value?.toString()) : 0;
      if (monthInput >= 1 && monthInput <= 12) {
        validMMDate = true;
      }
      return validMMDate ? null : { invalidDate: true };
    };
  }
  private checkValidYYExpiry(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let validYYDate = control?.value?.toString()?.length ? false : true;
      const yearInput = control?.value?.toString() ? parseInt(control?.value?.toString()) : 0;
      const currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4));
      if (yearInput && yearInput >= currentYear && yearInput <= 99) {
        validYYDate = true;
      }
      return validYYDate ? null : { invalidDate: true };
    };
  }

  public submitCardDetails(): void {
    this.submitted = true;
    if (this.cardDetailsForm?.valid) {
      this.submitBtnDisable = true;
      this.apiService.saveCardDetailsToStorage(
        this.cardDetailsForm?.value,
        this.cardNumber,
        this.userId
      );
      this.apiService.initiate3dsPayment()?.subscribe(
        (res: any) => {
          /**
           * This flow should be run when card is not 3ds verified
           * In that case the authenticated flag would be false
           * We redirect the user to the OTP flow by using url sent in response
           */
          if (
            // this.uiConfig?.defaultVerficationFlow &&
            res?.data?.formatted?.next?.redirect?.content &&
            res?.token &&
            res?.card_id &&
            !res?.authenticated
          ) {
            this.setActiveScreen('bankVerification');
            const cardTokenMap: Record<string, string> =
              this.apiService.getFromLocalStorage(
                'cardTokenMap'
              ) ?? {};
            cardTokenMap[res.card_id] = res.token;

            this.apiService.saveInLocalStorage(
              'cardTokenMap',
              cardTokenMap
            );

            const data = res.data.formatted.next.redirect;
            const {
              content: params,
              method = 'post',
              url: path,
              target = '',
              doc = window.document,
            } = data;

            const form = doc.createElement('form');
            form.method = method;
            form.action = path;
            if (target) {
              form.target = target;
            }

            for (const key in params) {
              // if (Object.prototype.hasOwnProperty.call(params, key)) {
              if (params.hasOwnProperty(key)) {
                const hiddenField = doc.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = key;
                hiddenField.value = params[key];

                form.appendChild(hiddenField);
              }
            }
            doc.body.appendChild(form);
            form.submit();
            // todo: add handling for flatten if needed

            // window.open(res.data.formatted.next.redirect.url, '_self');
          } else if (
            // !this.uiConfig?.defaultVerficationFlow &&
            res?.authenticated &&
            res?.card_id &&
            res?.token
          ) {
            /**
             * No one has clarity about this flow, to be handled in future as it comes
             * Rather than adding handling for the namesake, currently log error to test out more flows
             */
            this.router.navigate(['/offer'])
          } else if (
            // !this.uiConfig?.defaultVerficationFlow &&
            res?.data &&
            res?.id &&
            res?.token
          ) {
            /**
             * No one has clarity about this flow, to be handled in future as it comes
             * Rather than adding handling for the namesake, currently log error to test out more flows
             */
            console.error(
              'Case is not handled, please handle this case REFERENCE ###2###'
            );
          // eslint-disable-next-line no-dupe-else-if
          } else if (res?.authenticated && res?.token && res?.card_id) {
            /**
             * In this case, the card is already 3ds verified, so no need to go through the OTP flow
             */
            const cardTokenMap: Record<string, string> =
              this.apiService.getFromLocalStorage(
                'cardTokenMap'
              ) ?? {};
            cardTokenMap[res.card_id] = res.token;
            this.apiService.saveInLocalStorage(
              'is3dsVerified',
              'true'
            );
            this.apiService.saveInLocalStorage(
              'cardTokenMap',
              cardTokenMap
            );
            // this.fetchMerchantsList();
          } else if (
            !res?.authenticated &&
            res?.card_id &&
            res?.data?.formatted?.status === 'N'
          ) {
            /**
             * This scenario is to handle the case where the card number doesn't exist on the network
             * In this case, network returns the status as `N`, which means that the card number is incorrect
             * Note that the redirect block has `{url: null}` returned in this scenario, so we cannot redirect to any link as well
             * In this case the `content` block within `redirect` is not present, so the first `if` case of this overall method is not trigerred
             */
            
            //
            // this.toastrService.show(
            //   'Invalid card details entered, please recheck and try again',
            //   {
            //     classname: 'toast-failure',
            //   }
            // );
            this.submitBtnDisable = false;
          } else if (res?.data.error) {
            
            //
            // this.toastrService.show('Request failed. Please try again later.', {
            //   classname: 'toast-failure',
            // });
            this.submitBtnDisable = false;
          }
        },
        (err: {
          error: {
            errors: string[];
          };
        }) => {
          if (err?.error?.errors?.length) {
            this.customErrorMessage = 'Invalid Card number';
            this.cardDetailsForm.setErrors({
              customError: true,
            });
          }
          this.submitBtnDisable = false;
          this.submitBtnTitle = 'Proceed';
        }
      );
    }
  }

  public setActiveElement(element: string | null): void {
    setTimeout(() => {
      this.focusedElement = element;
    }, 0);
  }

  public numericKeyDownEvent(
    event: KeyboardEvent,
    isMobile = false
  ): void {
    if (
      ['Tab', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(
        event.key
      )
    ) {
      return;
    }

    if (!isMobile) {
      if (event.key === 'v' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        return;
      }
    }

    const isDigit = /\d/.test(event.key);

    if (
      !isDigit ||
      (isMobile && parseInt((event.target as HTMLInputElement).value) === 0)
    ) {
      event.preventDefault();
      return;
    }
  }
  public cardKeyUpEvent(event: any, index: any, nextHTMLElement: any): void {
    let pos = index;
    // this.fetchCardProvider(index);
    if (
      event.keyCode === 8 &&
      event.which === 8 &&
      this.cardFormRows._results[index]?.nativeElement?.value?.length == 0
    ) {
      pos = index - 1;
      if (pos > -1) {
        this.cardFormRows._results[pos]?.nativeElement?.focus();
      }
    } else {
      if (
        this.cardFormRows._results[index]?.nativeElement?.value?.length === 4
      ) {
        pos = index + 1;
      } else {
        return;
      }
    }
    if (pos > -1 && pos < 4) {
      this.cardFormRows._results[pos]?.nativeElement?.focus();
    } else if (pos == 4) {
      nextHTMLElement?.focus();
    }
  }
  public expiryMMKeyDownEvent(e: any, nextHTMLElement: any): void {
    if (this.keyDownEvent(e)) {
      setTimeout(() => {
        if (Number?.isInteger(parseInt(e?.key))) {
          const monthInput = this.cardDetailsForm
            ?.get('expiryMM')?.value?.toString();
          if (monthInput?.length === 2 ) {
            nextHTMLElement?.focus();
          }
        }
      }, 0);
    } else {
      e?.preventDefault();
    }
  }
  public expiryYYKeyDownEvent(e: any): void {
    if (this.keyDownEvent(e)) {
      setTimeout(() => {
        if (Number?.isInteger(parseInt(e?.key))) {
          const yearInput = this.cardDetailsForm
            ?.get('expiryYY')?.value?.toString();
        }
      }, 0);
    } else {
      e?.preventDefault();
    }
  }

  private keyDownEvent(e:any): boolean {
    if(Number?.isInteger(parseInt(e?.key)) ||
    e?.key === '/' ||
    e?.key === 'Backspace' ||
    e?.key === 'ArrowRight' ||
    e?.key === 'ArrowLeft'){
      return true;
    } else{
      return false;
    }
  }
  public setActiveScreen(screen: string, cardId?: string): void {
    this.showAuthSection = true;
    if (screen !== 'previous') {
      this.previousScreen = this.activeScreen;
      this.activeScreen = screen;
      this.submitted = false;
    }
    switch (screen) {
      case 'previous':
        this.setActiveScreen(this.previousScreen || 'mobile');
        break;
      case 'landing':
        this.submitBtnDisable = false;
        this.submitBtnTitle = 'Get started';
        break;
      case 'mobile':
        if(this.isSignUp){
          this.submitBtnTitle = 'Unlock my Vouchers';
        } else {
          this.submitBtnTitle = 'Request OTP';
        }
        this.submitBtnDisable = !this.mobileNumberForm?.valid;
        if(!this.changeNumberFLag){
          this.mobileNumberForm.reset();
          this.otpForm.reset();
          this.cardDetailsForm?.reset();
        }
        this.customErrorMessage = null;
        this.otpTimer = null;
        break;
      case 'otp':
        this.submitBtnDisable = true;
        this.submitBtnTitle = 'Submit';
        this.initiateOtpTimer();
        setTimeout(() => {
          this.otpFormRows._results[0]?.nativeElement?.focus();
        }, 0);
        break;
      case 'card':
        this.addNewCard = true;
        this.submitBtnTitle = 'Authenticate Card';
        this.submitBtnDisable = true;
        break;
      case 'bankAuthenticated':
        if (localStorage.getItem("currentUser")) {
          this.isLoggedIn = true;
          setTimeout(()=>{
            this.router.navigate(["/offer"]);
          },2000)
        }    
        break;
    }
  }

  private initiateOtpTimer(seconds = 180): void {
    const minutes: number = Math.floor(seconds / 60);
    this.otpTimer =
      minutes +
      ':' +
      (seconds - minutes * 60 < 10
        ? `0${seconds - minutes * 60}`
        : seconds - minutes * 60);
    seconds--;
    const interval = setInterval(() => {
      if (this.otpTimer) {
        const minutes: number = Math.floor(seconds / 60);
        this.otpTimer =
          minutes +
          ':' +
          (seconds - minutes * 60 < 10
            ? `0${seconds - minutes * 60}`
            : seconds - minutes * 60);
        if (seconds > 0) {
          seconds--;
        } else {
          this.otpTimer = null;
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 1000);
  }

  public handleSubmit() {
    if (this.activeScreen === 'landing') {
      if ((JSON.parse(localStorage.getItem('currentUser') as any) as IUser)?.token) {
        const currentURL = new URL(window.location.href);
        this.handleUserLogin(currentURL);
        return; 
      }
      this.setActiveScreen('mobile');
    } else if (this.activeScreen === 'mobile') {
      this.submitMobileNumber();
    } else if (this.activeScreen === 'otp') {
      this.submitOtp();
    } else if (this.activeScreen === 'card') {
      this.submitCardDetails();
    } else if (this.activeScreen === 'bankVerification') {
      this.setActiveScreen('bankAuthenticated');
    } else if (this.activeScreen === 'bankAuthenticated') {
      // redirect user ho home screen
      // alert("redirect to home screen")
      this.setActiveScreen('bankAuthenticated');
    }
  }

  public login(formReset = true): void {
    this.isSignUp = false;
    this.submitBtnTitle = 'Request OTP';
    this.submitted = false;
    console.log({"error":this.mobileNumberForm?.controls?.mobileNumber?.errors})
    if(formReset)    {
      this.submitted = false;
      this.mobileNumberForm.reset();
      this.mobileNumberForm.markAsPristine(); 
      this.mobileNumberForm.markAsUntouched();
      this.mobileNumberForm?.controls?.mobileNumber?.reset();
      this.mobileNumberForm?.controls?.mobileNumber?.setErrors(null);
      this.mobileNumberForm?.controls?.mobileNumber?.updateValueAndValidity();      
    }

// control.updateValueAndValidity();
    this.mobileNumberForm.controls['inpOne'].clearValidators();
    this.mobileNumberForm.controls['inpTwo'].clearValidators();
    this.mobileNumberForm.controls['inpThree'].clearValidators();
    this.mobileNumberForm.controls['inpFour'].clearValidators();

    this.mobileNumberForm.controls['inpOne'].updateValueAndValidity();
    this.mobileNumberForm.controls['inpTwo'].updateValueAndValidity();
    this.mobileNumberForm.controls['inpThree'].updateValueAndValidity();
    this.mobileNumberForm.controls['inpFour'].updateValueAndValidity();
    if(this.changeNumberFLag){
      this.setActiveScreen('mobile');
    } else {
      this.handleSubmit()
    }
  }
  public signup(formReset = true): void {
    this.isSignUp = true;
    this.submitted = false;
    this.submitBtnTitle = 'Unlock my Vouchers';
    console.log({"error":this.mobileNumberForm?.controls?.mobileNumber?.errors})
    if(formReset)    {
      this.mobileNumberForm.reset(); 
      this.mobileNumberForm.markAsPristine(); 
      this.mobileNumberForm.markAsUntouched();
      this.mobileNumberForm?.controls?.mobileNumber?.reset();
      this.mobileNumberForm?.controls?.mobileNumber?.setErrors(null);
      this.mobileNumberForm?.controls?.mobileNumber?.updateValueAndValidity();
    }
    this.mobileNumberForm.controls['inpOne'].setValidators([Validators.required, this.checkMinLength(4)]);
    this.mobileNumberForm.controls['inpTwo'].setValidators([Validators.required, this.checkMinLength(4)]);
    this.mobileNumberForm.controls['inpThree'].setValidators([Validators.required, this.checkMinLength(4)]);
    this.mobileNumberForm.controls['inpFour'].setValidators([Validators.required, this.checkMinLength(4)]);

    this.mobileNumberForm.controls['inpOne'].updateValueAndValidity();
    this.mobileNumberForm.controls['inpTwo'].updateValueAndValidity();
    this.mobileNumberForm.controls['inpThree'].updateValueAndValidity();
    this.mobileNumberForm.controls['inpFour'].updateValueAndValidity();
    if(this.changeNumberFLag){
      this.setActiveScreen('mobile');
    } else {
      this.handleSubmit()
    }
  }

  public changeNumber(): void {
    this.changeNumberFLag = true;
    if(this.isSignUp){
      this.signup(false)
    } else {
      this.login(false)
    }
  }

  public submitMobileNumber(isResend = false): void {
    this.submitted = true;
    if (
      this.mobileNumberForm.valid &&
      !this.submitBtnDisable
    ) {
      this.submitBtnTitle = 'Requesting';
      this.submitBtnDisable = true;
      this.cardNumber = "";
      this.mobileNumber = this.mobileNumberForm?.value?.mobileNumber;
      if(this.isSignUp){
        this.cardFormInput.forEach((inpNumber) => {
          this.cardNumber += this.mobileNumberForm?.value[inpNumber]?.toString();
        });
        this.apiService.saveInLocalStorage('cardDetails',{cardNumber:this.cardNumber});
      }
      this.generateOtp(isResend);
    }
  }
  public submitOtp(): void {
    let code = '';
    if (this.otpForm?.valid) {
      if (this.currentUser && this.currentUser?.id) {
        this.submitted = true;
        this.submitBtnDisable = true;
        Object.values(this.otpForm?.value)?.map((val) => (code += val));
        this.apiService
          .verifyOtp(this.userId, {
            code,
          })
          ?.subscribe(
            (res: {
              success?: boolean;
              token?: string;
              user_unlock_at?: Date;
              wrong_attempts_left?: number;
            }) => {
              setTimeout(() => {
                this.submitBtnDisable = false;
              }, 500);
              if (res?.success && this.currentUser && res.token) {
                this.currentUser = {
                  ...this.currentUser,
                  token: res.token,
                };
                localStorage.setItem(
                  'currentUser',
                  JSON.stringify(this.currentUser)
                );
                if(this.isSignUp){
                  this.setActiveScreen('card');
                } else {
                  this.redirectToOffer();
                }
              } else {
                this.otpForm.setErrors({
                  customError: true,
                });
                if (res?.user_unlock_at) {
                  this.showOTPLimitError(res);
                }
                if (res.wrong_attempts_left && res.wrong_attempts_left > 0) {
                  this.customErrorMessage = `Invalid OTP. ${
                    res.wrong_attempts_left
                  } more attempt${
                    res.wrong_attempts_left > 1 ? 's' : ''
                  } left.`;
                }
              }
            },
            (err: {
              error: {
                errors: string[];
              };
            }) => {
              if (err?.error?.errors?.length) {
                this.customErrorMessage = err?.error?.errors[0];
                this.mobileNumberForm.setErrors({
                  customError: true,
                });
              }
              this.submitBtnDisable = false;
            }
          );
      } else {
        this.setActiveScreen('mobile');
      }
    }
  }
  private generateOtp(isResend = false): void {
    const observer = {
      next: (res: { token?: string, user_id?: string, id?: string, code?: string, msg?: string }) => {
        this.submitBtnDisable = false;
        if (res?.id || res?.user_id) {
          this.submitted = false;
          this.userId = res?.id || res?.user_id || '';
          this.currentUser = {
            ...this.currentUser,
            id: this.userId,
          };
          this.setActiveScreen('otp');
          isResend && this.resetOtp();
        } else if (PROJECT_NAME.VISA_ACTIVATION === this.env.projectName) {
          if(res?.code === ERROR_CODES.UserNotEligible) {
            this.setActiveScreen('mobile');
            this.customErrorMessage = ERROR_MESSAGES.UserNotEligible
            this.mobileNumberForm.setErrors({
              customError: true,
            });
          } else if (res?.code === ERROR_CODES.UserNotQualified) {
            this.setActiveScreen('mobile');
            this.customErrorMessage = ERROR_MESSAGES.UserNotQualified;
            this.mobileNumberForm.setErrors({
              customError: true,
            });
          } else {
            this.customErrorMessage =  "We're sorry, either you haven't qualified for this  program yet or the mobile number entered is invalid.";
            this.mobileNumberForm.setErrors({
              customError: true,
            });
          }
        } else {
          if(isResend){
            this.setActiveScreen('mobile');
            this.customErrorMessage =  "Invalid Credentials";
          } else{
            this.customErrorMessage =  "We're sorry, either you haven't qualified for this  program yet or the mobile number entered is invalid.";
          }
          this.mobileNumberForm.setErrors({
            customError: true,
          });
        }
      },
      error: (err: {
        error: {
          errors: string[];
          wrong_attempts_left: number;
          user_unlock_at: Date | string;
          next_otp_at: Date | string;
        };
      }) => {
        this.setActiveScreen('mobile');
        if (err?.error?.errors?.length) {
          this.customErrorMessage = err?.error?.errors[0];
          if (err.error.user_unlock_at || err?.error?.next_otp_at) {
            const currentTime = new Date()?.getTime();
            const otpTime = new Date(
              err.error.user_unlock_at || err?.error?.next_otp_at
            )?.getTime();
            const diffTime = Math.ceil(
              Math.abs(otpTime - currentTime) / (1000 * 60)
            );
            this.customErrorMessage =
              this.customErrorMessage +
              `. Please try again in ${diffTime} minute${
                diffTime > 1 ? 's' : ''
              }.`;
          }
          this.mobileNumberForm.setErrors({
            customError: true,
          });
        }
      }
    };

    this.apiService
      .generateOtp(this.isSignUp, this.mobileNumber)
      ?.subscribe(observer);
  }
  private resetOtp(): void {
    this.isOtpResent = true;
    setTimeout(() => {
      this.isOtpResent = false;
    }, 3000);
  }
  private showOTPLimitError(res: any) {
    const currentTime = new Date()?.getTime();
    const lockDuration = new Date(res?.user_unlock_at)?.getTime();
    if (lockDuration > currentTime) {
      const diffTime = Math.ceil(
        Math.abs(lockDuration - currentTime) / (1000 * 60)
      );
      this.customErrorMessage = 'Maximum invalid otp attemps reached.';
      this.customErrorMessage =
        this.customErrorMessage +
        ` Please try again in ${diffTime} minute${diffTime > 1 ? 's' : ''}.`;
      return;
    }
  }
  public clearMobileInput(): void {
    this.mobileNumberForm?.reset();
  }

  public resendOTP(): void {
    if (!this.otpTimer) {
      this.mobileNumber = this.mobileNumberForm?.value?.mobileNumber;
      if (this.userId) {
        this.generateOtp(true);
      } else {
        this.setActiveScreen('mobile');
      }
    }
  }

  public focusFirstEmptyInput() {
    const firstEmptyBoxIndex = this.otpFormRows._results?.findIndex(
      (row: any) => {
        return Number.isNaN(parseInt(row?.nativeElement?.value));
      }
    );
    this.otpFormRows._results[firstEmptyBoxIndex]?.nativeElement?.focus();
  }
  public otpKeyUpEvent(event: any, index: any) {
    let pos = index;
    if (event.keyCode === 8 && event.which === 8) {
      pos = index - 1;
      if (pos > -1) {
        this.otpForm.controls[this.otpFormInput[pos]].setValue(null);
      }
    } else {
      if (this.otpForm?.controls[this.otpFormInput[pos]]?.value?.toString()) {
        pos = index + 1;
      } else {
        return;
      }
    }
    if (pos > -1 && pos < this.otpFormInput.length) {
      this.otpFormRows._results[pos].nativeElement.focus();
    }
  }

  public onOtpPaste(e: any) {
    const pasteData = e?.data || e?.clipboardData?.getData('text');
    if (pasteData?.length === 6) {
      if (Number.isInteger(parseInt(pasteData))) {
        this.otpFormInput.forEach((val, index) => {
          this.otpForm.controls[val].setValue(parseInt(pasteData[index]));
        });
        this.otpFormRows._results[
          this.otpFormInput?.length - 1
        ].nativeElement.focus();
      }
    }
  }


  public onMobileNumberPaste(e: any) {  
    const pasteData = e?.data || e?.clipboardData?.getData('text');  
    if (pasteData?.length !== this.mobileNumberLength.max) {  
      this.mobileNumberForm.controls['mobileNumber'].setErrors({'incorrectLength': true});  
      return;  
    }  
    const pastedNumber = parseInt(pasteData, 10);  
    if (!isNaN(pastedNumber)) {  
      this.mobileNumberForm.controls['mobileNumber'].setValue(pastedNumber);  
      this.mobileNumberForm.controls['mobileNumber'].setErrors(null);  
      return;  
    } 
    this.mobileNumberForm.controls['mobileNumber'].setErrors({'invalid': true}); 
  }
  public tryAgain(): void {
    this.apiService.deleteToken()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.clearSessionAndNavigate();
        },
        error: () => {
          this.clearSessionAndNavigate();
        }
      });
  }
  private clearSessionAndNavigate(): void {
    localStorage.clear();
    this.router.navigate(["/"]);
    this.setActiveScreen('mobile');
  }
}
