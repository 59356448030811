<div class="single-offer m-0 h-full overflow-hidden block border-round-lg mar-top-15 mar-bot-40 text-left relative flex flex-column">
    <div
        class="offer-img block">
        <img class="img-fluid " alt="offer card" [src]="offer.image_url || 'https://images.poshvine.com/icon/gv/rectangular-image-placeholder.jpeg'">
    </div>
    <div class="offer-rtxt-data p-3">
        <div class="w100">
            <div class="marchant-name font-bold pv-text-18px">{{offer.merchantName}}</div>
            <ng-container *ngIf = "!offer.redeemed_code; else voucherBlock">
                <div class="desc-box block">
                  <div class="offer-desc pv-text-16px pt-2 pb-2">
                      {{offer.offerTitle}}
                    </div>
              </div>
            </ng-container>
            
            <div class="flex justify-content-center align-items-center pt-3">
                <div  class="know-more font-semibold flex justify-content-center align-items-center cursor-pointer">
                  <span tabindex="0" (click)="clickMerchant($event, offer, 'Know more')" (keydown)="clickMerchant($event, offer, 'Know more')">Know more</span>
                </div>
                <button 
                  *ngIf = "!offer.redeemed_code"
                  class="btn bg-primary claim-button cursor-pointer"
                  [disabled]="btnDisabled"
                  (click)="emitRedeemOffer(offer, $event)">Claim</button>
            </div>
          </div>
    </div>
</div>
<ng-template #voucherBlock>
  <section class="voucher-box pv-p-10px mt-3">
    <div class="voucher font-semibold pv-text-16px text-center pt-1">{{offer.redeemed_code}}</div>
    <div class="flex align-content-center w-full justify-content-center pt-1">
      <span class="voucher-label pv-text-12px pr-1">Voucher Code</span>
      <pv-copy
        copyIcon="copy"
        [valueToBeCopied]="offer.redeemed_code"
        successIcon="circular-tick"
        >
      </pv-copy>
    </div>
  </section>
</ng-template>