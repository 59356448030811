import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '../../pages/common/api.service';
import { Router } from '@angular/router';
import { Merchant, Offer, OfferDetails } from '../offers.interface';
import { Subject, finalize, takeUntil } from 'rxjs';

export interface OfferCard extends Offer {
  merchant: Merchant;
  isDisabled: boolean;
}

@Component({
  selector: 'app-offers-card',
  templateUrl: './offers-card.component.html',
  styleUrls: ['./offers-card.component.scss']
})
export class OffersCardComponent {
  private destroy$: Subject<void> = new Subject<void>();
  @Input() offer!: OfferDetails;
  @Input() authenticated_user: boolean | undefined;
  @Input() category: any;
  @Output() public openKnowmore = new EventEmitter<OfferDetails>();

  public detailsUrlPath = '';
  public voucherCode!: string;
  public offerProgress = false;
  public btnDisabled = false;

  constructor(
    private apiService: ApiService,
    private router: Router,
  ) { }

  public emitRedeemOffer(merchant: any, event: { stopPropagation: () => void; }): void {
    event.stopPropagation();
    this.btnDisabled = true;
    if (this.apiService.isAuthenticated()) {
      this.claimOffer(merchant);
    } else {
      this.router.navigate(["/"]);
    }
  }
  private claimOffer(offer: OfferCard): void {
    this.offerProgress = true
    this.apiService.redeem(offer.id)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response: any) => {
          if (response?.success){
            this.offer.redeemed_code = response?.voucher_code ? response?.voucher_code : 'No coupon required';
          }
        },
        error: (e: any) =>{
          this.btnDisabled = false;
        }
      });
  }
  public clickMerchant(event: { stopPropagation: () => void; }, offer: OfferDetails, action: string): void {
    event.stopPropagation()
    this.openKnowmore.emit(offer);
  }
}
