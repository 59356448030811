<ng-container  *ngIf="userCards.length; else RedirectionBlock">
  <div id="cardSwitch" class="relative p-5 m-auto w-full">
    <h5 class="title pv-text-16px mb-2">Select Your Card</h5>
    <section class="cards mb-2">
      <ng-container *ngIf="userCards.length">
        <div *ngFor="let card of userCards"
        class="card-holder flex pb-3 pt-3">
            <div class="align-self-center card-name">
                {{card.attributes.display_name}}
            </div>
            <div class="select-btn flex justify-content-end">
                <button 
                  *ngIf="card.id !== selectedCardId"
                  class="btn bg-primary cursor-pointer" 
                  (click)="authService.setCard(card)"
                    >Select</button>
                <span class="selected-button font-bold" *ngIf="card.id===selectedCardId">Selected</span>
                
            </div>
        </div>
      </ng-container>
    </section>
    <h5 class="title pv-text-16px mt-4 mb-2" *ngIf="userCardsNotVerified.length">Not Verified Card</h5>
    <section class="cards">
      <ng-container *ngIf="userCardsNotVerified.length">
        <div *ngFor="let card of userCardsNotVerified"
        class="card-holder flex pb-3 pt-3">
            <div class="align-self-center card-name">
                {{card.attributes.display_name}}
            </div>
            <div class="select-btn flex justify-content-end">
                <button 
                  class="btn bg-primary cursor-pointer" 
                  (click)="reverify(card)"
                    >Re-verify</button>
            </div>
        </div>
      </ng-container>
    </section>
  </div>
</ng-container>
<ng-template #RedirectionBlock>
  <section class="text-center m-auto pt-6 pb-6 px-4">
    <img alt="Unlock-voucher-icon" loading="lazy" src="https://images.poshvine.com/visa-contactless/alert-triangle.png">
    <div class="pt-3 font-bold">Card is not verified yet. Please register again!</div>
    <div class="pt-3 pv-text-14px">You will be logged out in {{countdown}} seconds</div>
  </section>
</ng-template>