<section class="back-button pt-5 px-5 ">
    <div class="flex align-content-center" (click)="closeDetailsModal.emit(true)" (keypress)="closeDetailsModal.emit(true)" tabindex="0">
        <pv-svg-icon name="back" class="pv-mt-2px"></pv-svg-icon>
        <span class="ml-2">Back</span>
    </div>
    
</section>

<section class="merchant-block px-5 pt-4">
    <div class="flex m-flex-column gap-3">
        <div class="offer-image">
            <img class="w-full" alt="offer-image" [src]="offer?.image_url || 'https://images.poshvine.com/icon/gv/rectangular-image-placeholder.jpeg'">
          </div>
        <div class="offer-info">
            <div class="font-bold pv-text-24px">{{offer?.merchantName}}</div>
            <div class="pv-text-16px mt-2">{{offer?.offerTitle}}</div>
            <a target="_blank" rel="noreferrer noopener" class="ga-to-merchant block mt-4" [href]="offer?.redemption_url">
                <button class="btn bg-primary cursor-pointer">Go to Merchant</button>
            </a>
            
        </div>
        
    </div>
</section>

<section class="p-5">
    <div>
        <h3 class="pv-text-16px font-bold mb-2">About this deal</h3>
        <div class="pv-text-16px pre" [innerHtml] = "offer?.description"></div>
    </div>
    
    <div>
        <h3 class="pv-text-16px font-bold mb-2 mt-4">Terms and Conditions</h3>
        <div class="pv-text-16px pre" [innerHtml]="offer?.terms"></div>
    </div>
</section>