import { Component, OnInit, OnDestroy, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, finalize, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from '../../pages/common/api.service';
import { Merchant, Offer, OfferDetails, SpecialBundleResponse, SpecialBundle, MerchantData } from '../offers.interface';
import { Environment } from '../../environments/environment.interface';

export interface OfferCard extends Offer {
  merchant: Merchant;
  isDisabled: boolean;
}

@Component({ 
  selector: 'app-offer-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})
export class OffersListComponent implements OnInit, OnDestroy {
  public env:Environment = environment;
  public skelatonArray = new Array(this.env?.offerPage?.skelatonLength);
  
  categoryList = [];
  showUnlockOffer = false;

  public offers:OfferDetails[] = []
  private categorySubscription: Subscription | undefined;
  private currentPage = 1;
  public preventCallingHandler = false;
  private destroy$: Subject<void> = new Subject<void>();
  public isCategoryChange = false;
  public categoryIcon = '';
  public categoryRefresh = false;
  public showKnowMore = false;
  public selectedOffer: OfferDetails | null = null;

  @HostListener('unloaded')
  public ngOnDestroy(): void {
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe()
    }
    this.elementRef.nativeElement.remove();
    this.destroy$.next();
    this.destroy$.complete();
  }

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private apiService: ApiService,
  ) {
    window.scrollTo(0, 0);
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);
    this.checkAuth();
    this.getOffers();
  }
  authenticated_user = false;
  public checkAuth(): void {
    if (localStorage.getItem("currentUser")) {
      this.authenticated_user = true;
      this.router.navigate(["/offer"]);
    }
    else {
      this.authenticated_user = false;
      this.router.navigate(["/"]);
    }
  }
  

  private getOffers(): void {
    this.apiService.getOfferList()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response: SpecialBundleResponse) => {
          response?.data?.forEach((item: SpecialBundle) => {
            
            if (item?.attributes?.context === "activation" && response?.data?.length === 1){
              this.showUnlockOffer = true;
            }
            const offerAttributes = item?.attributes?.bundle_items?.data[0]?.attributes?.item?.data?.attributes;
            const merchant = item?.attributes?.bundle_items?.data[0]?.attributes?.item?.included.find(
              (includeItem: MerchantData) =>
                includeItem.type === 'merchant'
            );
            this.offers.push({
                id: offerAttributes.id,
                merchantName: merchant?.attributes?.name || '',
                offerTitle: offerAttributes.title,
                redemption_url: offerAttributes.redemption_url,
                terms: offerAttributes.terms,
                image_url: offerAttributes.image_url,
                end_date: offerAttributes.end_date,
                description: offerAttributes.description,
                redeemed_code: offerAttributes.redeemed_code
              }
            );
          });
        }
      });
  }

  public moveUp(): void {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  public onScroll(): void  {
    if (!this.preventCallingHandler) {
      this.currentPage += 1;
      this.getOffers();
    }
  }
  public selectedOfferId(offer: OfferDetails): void {
    this.selectedOffer = offer;
    this.showKnowMore = true;
  }

  public closeKnowMore(): void {
    this.showKnowMore = false;
  }
}
