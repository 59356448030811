import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OfferDetails } from '../offers.interface';

@Component({
  selector: 'app-details-modal',
  templateUrl: './details-modal.component.html',
  styleUrl: './details-modal.component.scss'
})
export class DetailsModalComponent {
  @Input() public offer: OfferDetails | null = null;
  @Output() closeDetailsModal = new EventEmitter<boolean>();
}
