import {
  Component,
  EventEmitter,
  OnInit,
  TemplateRef,
  ViewChild,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { ApiService } from '../../pages/common/api.service';
import { Router } from '@angular/router';
import { AuthService } from '../../pages/common/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserCard } from '@pv-frontend/pv-shared-services/common';
@Component({
  selector: 'app-card-switch',
  templateUrl: './card-switch.component.html',
  styleUrls: ['./card-switch.component.scss']
})
export class CardSwitchComponent implements OnInit, OnChanges {
  @Input() userCards: UserCard[] = [];
  @Input() userCardsNotVerified: UserCard[] = [];
  selectedCardId: string | null = null;
  public countdown = 5;
  @ViewChild('cardSwitch') cardSwitch: TemplateRef<any> | any;
  emitCloseSwitchPanelEvent: EventEmitter<string> = new EventEmitter<string>();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public apiService: ApiService,
    private router: Router,
    public authService: AuthService,
  ) {
  }

  public ngOnInit(): void {
    if (localStorage.getItem('uc')) {
      this.selectedCardId = localStorage.getItem('uc');
    }
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.userCards && changes.userCards.currentValue.length === 0) {
      this.startCountdown();
    }
  }
  
  public closePanel(): void {
    this.emitCloseSwitchPanelEvent.emit('close');
  }
  reverify() :void {
    this.apiService.deleteToken().subscribe((res: any) => {
      localStorage.clear();
      this.router.navigateByUrl(`/?reverify=true`);
    },
    (err) => {
      localStorage.clear();
      this.router.navigateByUrl(`/?reverify=true`);
    });
  }

  private startCountdown(): void {
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown === 0) {
        clearInterval(interval);
        this.reverify();
      }
    }, 1000);
  }
}