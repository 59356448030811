import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { ValueOf } from '@pv-frontend/pv-shared-services/common';
import { Subject, takeUntil } from 'rxjs';

export const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
} as const;

export type Device = ValueOf<typeof DEVICES>;

@Injectable({
  providedIn: 'root',
})
export class DeviceService implements OnDestroy {
  public currentDevice: Device = this.getCurrentDevice();
  public isMobileOnResize: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isMobile: boolean = this.isCurrentDeviceMobile();
  private destroy$: Subject<void> = new Subject<void>();

  public constructor() {
    this.isMobileOnResize
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile: boolean) => {
        this.isMobile = isMobile;
      });
  }

  private getCurrentDevice(): Device {
    return window.innerWidth < 768 ? DEVICES.MOBILE : DEVICES.DESKTOP;
  }

  public isCurrentDeviceMobile(): boolean {
    return this.getCurrentDevice() === DEVICES.MOBILE;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
