<section class="main-container">
  <section *ngIf="!offers.length" class="flex align-items-center justify-content-around w-full gap-4">
    <div *ngFor="let offerPlaceholderSkeleton of skeletonArray">
      <p-skeleton width="18rem" height="20rem" styleClass="mb-2" />
    </div>
  </section>
  <section>
    <section class="special-offers flex flex-row gap-4 container mar-top-25 row px-0 mx-auto">
      <div class="offer-card" *ngFor="let offer of offers">
        <app-offers-card 
          [offer]="offer"
          [authenticated_user]="authenticated_user"
          (openKnowmore)="selectedOfferId($event)">
        </app-offers-card>
      </div>
      
      <div 
        *ngIf = "env?.offerPage?.showUnlockOfferFlag && showUnlockOffer"
        class="semi-bg-primary text-color flex justify-content-around surface-200 border-round p-4 align-items-center inactive-card">
        <div class="flex flex-column align-center">
          <img class="unlock-icon m-auto" [width]="40" [height]="40" alt="Unlock-voucher-icon" loading="lazy" src="https://images.poshvine.com/visa-contactless/lockForMoreOffer.png">
          <p class="text-black text-center mt-3 w-full m-auto">Make two more Tap to Pay transactions to earn INR 200 Amazon voucher.</p>
        </div>
      </div>
    </section>
  </section>

</section>

<pv-drawer
  id="know-more-modal"
  *ngIf="selectedOffer"
  [isVisible]="showKnowMore"
  position="bottom"
  [showCloseIcon]="false"
  (closeHandler)="closeKnowMore()"
  [showBackdrop]="true"
  [dismissOnBackdropClick]="true"
  [closeOnEscape]="true">
  <app-details-modal [offer] = "selectedOffer" (closeDetailsModal) = "closeKnowMore()"></app-details-modal>
</pv-drawer>