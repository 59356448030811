<div class="mobile-flex bg-primary flex flex-column justify-content-between text-color mt-5 h-full">
  <button class="bg-white p-2 border-none m-auto w-6 cursor-pointer" (click)="toggleAuthSection('signup')">Get Started</button>
  <button class="text-white bg-primary p-2 border-none m-auto w-6 cursor-pointer" (click)="toggleAuthSection('login')">
    <span class="btn-subtext font-semibold">Returning user?</span> Login
  </button>
</div>
<div class="mobile-flex bg-primary p-3 flex flex-column mt-3 gap-4">
  <p class="flex flex-column gap-3">How to avail this offer? </p>
  <div class="flex flex-row gap-3 align-items-center">
    <img alt="Unlock-voucher-icon" [width]="40" [height]="40" loading="lazy" src="https://images.poshvine.com/visa-contactless/1.svg">
    <div>
      <p class="font-bold">{{ env?.homePage?.stepOneHeading }}</p>
      <p class="instruction-sub-label">{{ env?.homePage?.stepOneDetails }}</p>
    </div>
  </div>
  <div class="flex flex-row gap-3 align-items-center">
    <img alt="Unlock-voucher-icon" [width]="40" [height]="40" loading="lazy" src="https://images.poshvine.com/visa-contactless/2.svg">
    <div>
      <p class="font-bold">{{ env?.homePage?.stepTwoHeading }}</p>
      <p class="instruction-sub-label">{{ env?.homePage?.steoTwoDetails }}</p>
    </div>
  </div>
</div>
<section *ngIf="showAuthSection" class="flex flex-column justify-content-between text-color h-full h-36rem bg-white m-sticky-bottom">
  <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
  <img class="m-cross" [height]="40" [width]="40" alt="Unlock-voucher-icon" loading="lazy" (click)="closeAuthSection()" src="https://images.poshvine.com/visa-contactless/cross.svg">
  <div *ngIf="activeScreen === 'landing'" class="flex flex-column m-auto px-4 align-items-center">
    <p class="text-2xl font-semibold text-center text-primary	m-2">{{ env?.homePage?.signUpHeading }}</p> 
    <p class="text-center m-2">Provide your Card details and check your eligibility</p>
  </div>
  <div *ngIf="activeScreen === 'mobile'">
    <div class="p-4 flex flex-column h-full">
      <p class="text-2xl font-semibold text-primary	my-3">{{ env?.homePage?.signUpHeading }}</p>
      <form [formGroup]="mobileNumberForm">
        <p class="text-xs font-semibold py-2" *ngIf="isSignUp === true">Enter Your Visa Card Number</p>
        <div 
          *ngIf="isSignUp === true"
          class="border-round flex justify-content-between align-items-center p-0"
          [ngClass]="{
            'is-invalid':
              submitted &&
              (mobileNumberForm.errors ||
                mobileNumberForm.controls.inpOne.errors ||
                mobileNumberForm.controls.inpTwo.errors ||
                mobileNumberForm.controls.inpThree.errors ||
                mobileNumberForm.controls.inpFour.errors)
          }"
        >
          <div
            class="h-3rem"
            *ngFor="let inp of cardFormInput; let idx = index"
          >
            <input
              #cardFormRow
              id="cardNumberInp"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="tel"
              inputmode="numeric"
              [formControlName]="inp"
              maxlength="4"
              class="p-3 border w-5rem text-input-border letter-spacing"
              required
              (focus)="setActiveElement('cardNumberInp')"
              (keydown)="numericKeyDownEvent($event)"
              (keyup)="cardKeyUpEvent($event, idx, mobileInput)"
              (blur)="setActiveElement(null)"
              [ngClass]="{
                'is-invalid':
                  submitted && mobileNumberForm.controls[inp].errors
              }"
            />
          </div>
        </div>
        <p class="text-xs font-semibold mt-3 py-2">Enter your mobile number</p> 
        <div class="flex w-full gap-3">
          <div class="flex w-4rem align-items-center country">
            <img
              class="m-1"
              [width]="20"
              [height]="15"
              alt="Indian-flag"
              src="https://images.poshvine.com/visa-contactless/Flag.png"
            />
            (+91)
          </div>
          <input
            #mobileInput
            autofocusInp
            type="number"
            inputmode="numeric"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            formControlName="mobileNumber"
            [maxlength]="mobileNumberLength.max"
            class="p-3 w-full text-input-border"
            required
            (keydown)="numericKeyDownEvent($event, true)"
            (focus)="setActiveElement('mobNoInp')"
            (blur)="setActiveElement(null)"
            placeholder="Enter 10-digit number"
            [ngClass]="{
              'is-invalid':
                submitted &&
                (mobileNumberForm.errors ||
                  mobileNumberForm.controls.mobileNumber.errors)
            }"
          />
        </div>
        <div class="flex mt-3 py-2 w-full gap-2">
          <input
            autofocusInp
            type="checkbox"
            inputmode="checkBox"
            formControlName="concent1"
            class="text-input-border align-self-start mt-1"
            required
            [ngClass]="{
              'is-invalid':
                submitted &&
                (mobileNumberForm.errors ||
                  mobileNumberForm.controls.concent1.errors)
            }"
          />
          <div class="w-full consent align-items-center country">
            By signing in, you agree to our
            <a class="underline " target=”_blank” href="/info/terms-and-conditions"> Terms & Conditions </a>
            & <a class="underline " target=”_blank” href="/info/privacy-policy"> Privacy Policy </a>.
   
          </div>
        </div>
        <div class="flex py-2 w-full gap-2">
          <input
            autofocusInp
            type="checkbox"
            inputmode="checkBox"
            formControlName="concent2"
            class="text-input-border align-self-start mt-1"
            required
            [ngClass]="{
              'is-invalid':
                submitted &&
                (mobileNumberForm.errors ||
                  mobileNumberForm.controls.concent2.errors)
            }"
          />
          <div class="w-full consent text-xs align-items-center country">
            By checking this box, you agree that Visa may collect, use, process and share your personal information for the purposes 
            of administering and managing the Campaign in accordance with Visa's <a class="underline" target=”_blank” href="https://www.visa.co.in/legal/global-privacy-notice.html"> Global Privacy Notice </a>.
          </div>
        </div>
        <small *ngIf="mobileNumberForm?.errors?.customError" class="text-red-500 text-sm">
            {{customErrorMessage}}
        </small>
        <small *ngIf="
                    submitted &&
                    (mobileNumberForm?.errors ||
                      mobileNumberForm?.controls?.mobileNumber?.errors)
                  " class="text-red-500 text-sm">
          <div *ngIf="this.mobileNumber.length > 0 && mobileNumberForm?.controls?.mobileNumber?.errors">
            Please enter valid mobile number
          </div>
        </small>
      </form>
    </div>
  </div>
  <div *ngIf="activeScreen === 'otp'">
    <div class="p-4 flex flex-column mt-0 mt-md-3">
      <div class="px-3" >
        <p class="text-2xl font-semibold text-primary	my-3">Unlock your Vouchers</p>
        <div class="flex flex-row">
        <p
          class=" opacity-50"
        >
          Enter OTP sent to
          <span>+91 - {{ this.mobileNumber }} </span>
        </p>
        <!-- Do not add the slice pipe again without testing it is breaking js -->
        <!-- eslint-disable-next-line @angular-eslint/template/click-events-have-key-events, @angular-eslint/template/interactive-supports-focus -->
        <button (click)="changeNumber()" class="opacity-100 border-none ml-1 bg-white text-primary w-100 font-semibold cursor-pointer"> Change</button>
      </div>
    </div>

      <form
        [formGroup]="otpForm"
        class="rounded-top pt-2 px-3 px-md-5"
      >
        <div class="mb-3">
          <div class="flex justify-content-between mar-bot-4">
            <div
              *ngFor="let input of otpFormInput; index as i"
            >
              <input
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                #otpFormRow
                [ngStyle]="{ border: '1px solid ' + uiConfig?.primaryColor }"
                type="number"
                inputmode="numeric"
                formControlName="{{ input }}"
                class="w-3rem h-3rem text-center text-input-border"
                maxlength="1"
                (focus)="focusFirstEmptyInput()"
                (keyup)="otpKeyUpEvent($event, i)"
                (paste)="onOtpPaste($event)"
                (input)="onOtpPaste($event)"
                [class.otp-invalid]="otpForm.invalid"
              />
            </div>
          </div>
          <small
            *ngIf="submitted && otpForm?.errors"
            class="text-danger fnt-10"
          >
            <span *ngIf="otpForm?.errors">{{ customErrorMessage }}</span>
          </small>
        </div>
      </form>
      <div class="flex px-3 fnt-12 mar-bot-8 f400">
        <!-- <p class="text-fade mr-1">Resend OTP </p> -->
        <!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus, @angular-eslint/template/click-events-have-key-events -->
        <p
          class="ms-1"
          *ngIf="otpTimer !== null"
        >
         {{ "Resend OTP in " + otpTimer }}
        </p>
        <button
          class="ms-1 btn bg-primary cursor-pointer"
          (click)="resendOTP()"
          *ngIf="otpTimer === null"
        >Resend OTP</button>
      </div>
      <small class="text-success fnt-10 text-center" *ngIf="isOtpResent"
        >OTP resent successfully.</small
      >
    </div>
  </div>
  <div *ngIf="activeScreen === 'card'">
    <div class="p-4 flex flex-column mt-0 mt-md-3">
    <p class="text-2xl font-semibold text-primary	my-3">Card verification</p>
    <form
      class="card-form"
      [formGroup]="cardDetailsForm"
    >
    <div class="flex flex-row border-gray-100 border-1 font-normal align-items-center border-round pb-1">
      <img [height]="12" class="pl-2" alt="visa-logo" loading="lazy" src="https://images.poshvine.com/visa-contactless/visalogo.png">
      <div class="flex gap-1 flex-column p-2">
        <span class="text-xs line-height-1">Visa Card</span>
        <span class="line-height-1">{{cardNumber | slice:0:4 }} - {{cardNumber | slice:4:6}}XX - XXXX- {{cardNumber | slice:-4}}</span>
      </div>
    </div>
    <div class="flex flex-column gap-1 w-full border-round border">
      <p class="text-xs font-semibold mt-3 py-2">Expiry Date (MM/YY)</p>
      <div class="flex flex-row gap-2">
        <input
          #expiryInpMM
          type="text"
          inputmode="numeric"
          formControlName="expiryMM"
          maxlength="2"
          class="text-input-border w-full p-2 px-3"
          required
          placeholder="MM"
          (focus)="setActiveElement('expiryMM')"
          (keydown)="numericKeyDownEvent($event)"
          (keyup)="expiryMMKeyDownEvent($event,expiryInpYY)"
          (blur)="setActiveElement(null)"
          [ngClass]="{
            'is-invalid':
              submitted && cardDetailsForm.controls.expiryMM.errors
          }"
        />
        <input
          #expiryInpYY
          type="text"
          inputmode="numeric"
          formControlName="expiryYY"
          maxlength="2"
          class="text-input-border w-full p-2 px-3"
          required
          placeholder="YY"
          (focus)="setActiveElement('expiryYY')"
          (keydown)="numericKeyDownEvent($event)"
          (keyup)="expiryYYKeyDownEvent($event)"
          (blur)="setActiveElement(null)"
          [ngClass]="{
            'is-invalid':
              submitted && cardDetailsForm.controls.expiryYY.errors
          }"
        />
      </div>
      <small *ngIf="submitted && cardDetailsForm?.controls?.expiryMM?.errors?.required" class="text-danger fnt-10">
        Expiry Month is required
      </small>
      <small *ngIf="submitted && cardDetailsForm?.controls?.expiryMM?.errors?.invalidDate" class="text-danger fnt-10">
        Expiry Month is invalid
      </small>
      <small *ngIf="submitted && cardDetailsForm?.controls?.expiryYY?.errors?.required" class="text-danger fnt-10">
        Expiry Year is required
      </small>
      <small *ngIf="submitted && cardDetailsForm?.controls?.expiryYY?.errors?.invalidDate" class="text-danger fnt-10">
        Expiry Year is invalid
      </small>
    </div>
    <!-- <div class="flex flex-row bg-gray-100 font-normal align-items-center border-round mt-3 p-1">
      <img class="m-1" [width]="34" [height]="34" alt="Indian-flag" src="https://images.poshvine.com/visa-contactless/pcidss.png"/>
      <span class="text-xs">Card and account numbers are only used to check your eligibility and are encrypted.</span>
    </div> -->
  </form>
  </div>
  </div>
  <div *ngIf="activeScreen === 'bankVerification'" class="flex flex-column p-4 align-items-center m-auto">
    <img alt="Unlock-voucher-icon" loading="lazy" src="https://images.poshvine.com/visa-contactless/waiting.png">
    <p class="text-2xl font-semibold text-center text-primary	m-2">Waiting for your bank...</p> 
    <p class="text-center m-2">This may take a few minutes</p>
  </div>
  <div *ngIf="activeScreen === 'bankAuthenticated'" class="flex flex-column p-4 align-items-center m-auto">
    <img alt="Unlock-voucher-icon" loading="lazy" src="https://images.poshvine.com/visa-contactless/authenticated.png">
    <p class="text-2xl font-semibold text-center text-primary	m-2">Authentication Successful!</p> 
    <p class="text-center m-2">Unlocking your vouchers...</p>
  </div>
  <div *ngIf="activeScreen === 'bankAuthenticationFailed'" class="flex flex-column p-4 align-items-center m-auto">
    <img alt="Unlock-voucher-icon" loading="lazy" src="https://images.poshvine.com/visa-contactless/alert-triangle.png">
    <p class="text-2xl font-semibold text-center text-primary	m-2">Authentication Failed!</p> 
    <button
      class="btn bg-white text-primary w-100 font-semibold cursor-pointer"
      (click)="tryAgain()"
    >Try Again</button>
  </div>
  <div
    class="bg-white sticky bottom-0 py-3 px-4 flex flex-column"
    *ngIf="!showLoader && submitBtnTitle"
  >
    <!-- <div *ngIf="activeScreen === 'mobile' || activeScreen === 'otp'" class="flex flex-row bg-gray-100 font-normal align-items-center border-round mb-3 p-1">
      <img class="m-1" [width]="34" [height]="34" alt="Indian-flag" src="https://images.poshvine.com/visa-contactless/pcidss.png"/>
      <span class="text-xs">Card and account numbers are only used to check your eligibility and are encrypted.</span>
    </div> -->
    <span *ngIf="activeScreen === 'card'" class="text-xs m-auto mb-2 text-gray-600">
      You'll be redirected to the issuer's website*
      <br/>
      *Visa shall not be responsible for any error in communication between you and the issuer</span>
    <button
      *ngIf="submitBtnTitle"
      class="btn bg-primary w-100 cursor-pointer"
      [ngStyle]="{
        backgroundColor: uiConfig?.primaryColor,
        borderColor: uiConfig?.primaryColor
      }"
      [disabled]="submitBtnDisable"
      (click)="handleSubmit()"
    >
      {{ submitBtnTitle }}
    </button>
    <button
      *ngIf="activeScreen === 'landing' || (activeScreen === 'mobile' && isSignUp)"
      class="btn bg-white text-primary w-100 font-semibold cursor-pointer"
      [ngClass]="submitBtnTitle ? 'mar-top-16' : ''"
      [ngStyle]="{
        backgroundColor: uiConfig?.primaryColor,
        borderColor: uiConfig?.primaryColor
      }"
      (click)="login(true)"
    >
      <span class="btn-subtext font-semibold">Returning user?</span> Login
    </button>
    <button
      *ngIf="activeScreen === 'mobile' && !isSignUp"
      class="btn bg-white text-primary w-100 font-semibold cursor-pointer"
      [ngClass]="submitBtnTitle ? 'mar-top-16' : ''"
      [ngStyle]="{
        backgroundColor: uiConfig?.primaryColor,
        borderColor: uiConfig?.primaryColor
      }"
      (click)="signup(true)"
    >
    Register
    </button>
  </div>
</section>
<ng-template #backNavTemplate let-default> </ng-template>
